import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Stage, Layer, Rect, Circle, Line, Text, Group, Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';
import {
    RowWrapper,
    ZoomGroupWrapper,
    ExhibitorWapper,
    StageWrapper,
    TopLeftWrapper,
    TopRightWrapper,
    TopWrapper,
} from "./style";
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';
import LaunchIcon from '@mui/icons-material/Launch';
import JsPDF from 'jspdf';

import ExhibitorList from './ExhibitorList';
import DialogBookingDetails from './DialogBookDetail';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const KonvaShape = ({ data, clickShape, changePosition }: any) => {

    const book_status_map = {
        'nope': 'Available',
        'pending': 'Reserved',
        'approved': 'Company Name'
    }
    const rectWidth = data.width * data.scale;
    const rectHeight = data.height * data.scale;
    const circleRadius = data.diameter / 2.0 * data.scale;
    const stroke = '#000000', strokeWidth = 2;
    const fontSize = 16;
    let details_width = 280;//350
    let details_height = 80;
    if (data.status == "approved" && data.company_name) {
        if (data.company_name.length > 30) {
            details_height = details_height + 30;
        }
    }

    let booth_color = '#0a0';//f2f6fa #0a0
    let stroke_color = '#666'; // #666
    // if (!!data.booked) { booth_color = '#003580' }
    if (!!data.selected) {
        stroke_color = '#0000FF';
    }

    const [position, setPosition]: any = useState({ x: data.x, y: data.y });
    const points: any = [
        0,
        0,
        data.direction === "horizontal" ? data.length * data.scale : 0,
        data.direction === "vertical" ? data.length * data.scale : 0
    ];

    const [isHover, setIsHover] = useState(false);

    const handleClickGroup = (e: any) => {
        // console.log('Group click', data);
        clickShape(data);
    }
    const handleHover = (e: any) => {
        const stage = e.target.getStage();
        if (stage) {
            if (e.type === "mouseenter") {
                stage.container().style.cursor = "pointer";
                setIsHover(true);
            } else {
                stage.container().style.cursor = "default";
                setIsHover(false);
            }
        }
    };
    const getBoothColor = () => {
        let color = booth_color;// default booth color

        if (isHover || data.selected) {
            color = data.status == 'approved' ? '#800080' : (data.status == 'pending' ? '#800080' : '#008000');

        } else {// nomarl status
            color = data.status == 'approved' ? '#7cdcf9' : (data.status == 'pending' ? '#f97c90' : '#808080');
        }

        return color;
    }
    return (
        <Group
            x={position.x}
            y={position.y}
            rotation={data.rotation}
            // draggable
            // onDragMove={handleDragMove}
            onClick={handleClickGroup}
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
        >
            {data.type === 'rectangle' && (<>
                <Rect
                    width={rectWidth}
                    height={rectHeight}
                    stroke={stroke_color}
                    strokeWidth={5}
                    fill={getBoothColor()}//#f2f6fa
                    opacity={0.3}
                />
                {(data.selected || isHover) &&
                    <Group
                        x={-details_width / 2 + rectWidth / 2}
                        y={-details_height - 15}
                        rotation={-data.rotation}
                    >
                        <Rect
                            width={details_width}
                            height={details_height}
                            x={0}
                            stroke='#666'
                            strokeWidth={3}
                            fill={'#f1f1f1'}//#f2f6fa
                            opacity={0.9}
                        />

                        <Text
                            x={10}
                            y={10} // Adjust for vertical centering
                            width={details_width}
                            text={data.status == "approved" ? data.company_name : (data.status == "pending" ? "Reserved" : 'Available')}
                            fontSize={18}
                            fontFamily='Arial'
                            fontWeight="600"
                            fill={data.status == "approved" ? '#010101' : (data.status == "pending" ? "#803580" : '#ff8000')}
                            align='left'
                            padding={5}
                            verticalAlign='middle'
                        // wrap="nope"
                        />
                        <Text
                            x={15}
                            y={details_height - 30} // Adjust for vertical centering
                            // width={rectWidth}
                            text={`Booth # ${data.no}`}
                            fontSize={18}
                            fontFamily='Arial'
                            fontWeight="600"
                            fill='#808080'
                            align='right'
                            verticalAlign='middle'
                        />
                        {
                            !data.company_name && (// when unbooked
                                <Text
                                    x={details_width - 160}
                                    y={details_height - 20} // Adjust for vertical centering
                                    // width={rectWidth}
                                    text={`Enquire for more details`}
                                    fontSize={14}
                                    fontFamily='Arial'
                                    fontWeight="500"
                                    fill='#000000'
                                    align='center'
                                    verticalAlign='middle'
                                />
                            )
                        }
                    </Group>}
            </>)}
            {data.type === 'circle' && (<>
                <Circle
                    radius={circleRadius}
                    stroke="black"
                    strokeWidth={4}
                />
            </>)}
            {data.type === 'line' && (<>
                <Line
                    points={points}
                    stroke={stroke}
                    strokeWidth={strokeWidth}
                />
            </>)}
            {data.type === 'text' && (<>
                <Text
                    x={data.x} // Start text from the left edge of the circle
                    y={data.y - fontSize / 2} // Center text vertically
                    // width={ data.text.length * 10 * data.scale } // The total width of the text is the diameter of the circle
                    text={data.text}
                    fontSize={fontSize}
                    fontFamily="Arial"
                    fill="#000000"
                    align="left"
                />
            </>)}
        </Group>
    );
};

interface BackgroundImageProps {
    src: string; // Type for image source path
}

const BackgroundImage: React.FC<BackgroundImageProps> = ({ src }) => {
    const [image, setImage]: any = useState<HTMLImageElement | null>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const img = new window.Image();
        img.src = src;
        img.onload = () => {
            setImage(img); // Once loaded, set the image into state
            // Set the natural dimensions of the image
            setDimensions({
                width: img.naturalWidth,
                height: img.naturalHeight,
            });

        };
    }, [src]); // Effect dependency array

    return (
        <KonvaImage
            image={image}
            width={dimensions.width}  // Set the KonvaImage width to the image's naturalWidth
            height={dimensions.height} // Set the KonvaImage height to the image's naturalHeight
        />
    );
};

const FloorPlanKonva = ({ event, map, shapes, exhibitors, onHoverListItem }: any) => {
    const stageRef: any = useRef();

    const scaleBy = 1.1;
    const floorplan = `${process.env.REACT_APP_SERVER_URL}/${map.image}`;
    const bottomWrapperRef = useRef<HTMLDivElement>(null); // Create a ref for the BottomWrapper div

    const [position, setPosition] = useState({ x: -200, y: 0 });
    const [mouseScale, setMouseScale] = useState(0.93);
    const [printScale, setPrintScale] = useState(0.82);

    const [x0, setX0] = useState(0);
    const [y0, setY0] = useState(0);

    const [zoom_type, setZoomType] = useState('zoom-in');

    const [showDialog, setShowDialog] = useState(false);
    const [bookingInfo, setBookingInfo] = useState({});

    const [backgroundImage, status] = useImage(floorplan);
    const [size, setSize] = useState<{ width: number; height: number }>({
        width: window.innerWidth - 400,
        height: window.innerHeight - 200,
    });

    useEffect(() => {
        // Ensure that the image dimensions are set only when the image status is 'loaded'
        if (status === 'loaded' && backgroundImage) {
            // console.log('background image', backgroundImage.width, backgroundImage.height, size);
            const newRatio = Math.min(size.width / backgroundImage.width, size.height / backgroundImage.height);
            setPrintScale(newRatio);
            setMouseScale(newRatio);

            // setSize({
            //     width: backgroundImage.width,
            //     height: backgroundImage.height,
            // });
        }
    }, [status, backgroundImage]);

    const handleShapeClick = (shape: any) => {

        onHoverListItem({ booth: -1 });

        const bookings = exhibitors.filter((exhibitor: any) => exhibitor.booth == shape.no);
        // console.log('booking details', bookings[0]);
        if (bookings.length > 0 && bookings[0].status == "approved") {
            setBookingInfo({
                ...bookings[0],
            });
            setShowDialog(true);
        }
    }
    const handlePosition = (position: any) => {
        let temp = shapes;
        temp = temp.map((item: any) => {
            if (item.key === position.key) {
                return {
                    ...item,
                    x: position.x,
                    y: position.y,
                }
            }
            return item;
        })
        // setShapes( temp );
    }
    const handleWheel = (e: any) => {
        const group = e.target.getStage();
        e.evt.preventDefault();
        const mousePointTo = {
            x: group.getPointerPosition().x / mouseScale - group.x() / mouseScale,
            y: group.getPointerPosition().y / mouseScale - group.y() / mouseScale
        };
        const newScale = e.evt.deltaY < 0 ? mouseScale * scaleBy : mouseScale / scaleBy;
        setMouseScale(newScale);
        setX0(-(mousePointTo.x - group.getPointerPosition().x / newScale) * newScale);
        setY0(-(mousePointTo.y - group.getPointerPosition().y / newScale) * newScale);
    };
    const handleZoomEvent = (newVal: string) => {
        setZoomType(newVal);
        if (newVal === null) {
            return;
        }
        if (newVal == 'zoom-in') {
            setMouseScale(mouseScale * scaleBy);
        } else {
            setMouseScale(mouseScale / scaleBy);
        }
    }
    const creatShape = (shape: any) => {
        return (<KonvaShape key={shape.key} data={shape} clickShape={handleShapeClick} changePosition={handlePosition} />);
    }
    const onHoverItem = (item: any) => {
        onHoverListItem(item);
    }
    const onClickItem = (item: any) => {
        console.log('clicked item', item);
        setBookingInfo({ ...item });
        setShowDialog(true);

    }
    const onPrint = async (e: any) => {
        console.log('print function');

        stageRef.current.position({ x: 0, y: 0 });
        setMouseScale(printScale);
        // return;

        // const uri = stageRef.current.toDataURL();
        // console.log(uri);
        setTimeout(async () => {
            const pdf = new JsPDF({
                orientation: 'landscape',//portrait
            });

            // Define the color you want for your background
            // const backgroundColor = '#FFFF00';

            // Set font styles if needed
            pdf.setFontSize(12);
            pdf.setFont('Roboto', 'normal');

            // const dataURL = stageRef.current.toDataURL({ pixelRatio: 1, mimeType: 'image/jpeg', quality: 0.5 });
            const dataURL = stageRef.current.toDataURL({ pixelRatio: 1, mimeType: 'image/png', quality: 0.5 });
            // Assume 'pdf' is an instance of jsPDF and 'dataURL' is the image data URL

            // Get image properties from the data URL
            const imgProps = pdf.getImageProperties(dataURL);

            // Get PDF's width and height
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            // // Set the background color
            // pdf.setFillColor(backgroundColor);
            // pdf.rect(0, 0, pdfWidth, pdfHeight, 'F'); // 'F' stands for the fill


            // Calculate the aspect ratio of the image
            const aspectRatio = imgProps.width / imgProps.height;

            // Assuming we want to maintain the aspect ratio of the image,
            // we calculate the scaled width and height to fit within the PDF dimensions
            let imgWidth = pdfWidth;
            let imgHeight = imgWidth / aspectRatio;

            // If the image height exceeds the page height, we rescale it
            if (imgHeight > pdfHeight) {
                imgHeight = pdfHeight;
                imgWidth = imgHeight * aspectRatio;
            }

            // Calculate positions to center the image
            const startX = (pdfWidth - imgWidth) / 2;
            const startY = (pdfHeight - imgHeight) / 2;

            // Add the centered image to the PDF
            pdf.addImage(dataURL, 'JPEG', startX, startY, imgWidth, imgHeight);

            // Add a new page to PDF
            pdf.addPage();

            pdf.text(`Exhibitors for ${event.full_name}`, 10, 20);

            const page2Data = exhibitors.filter((item: any) => item.status == 'approved');

            let baseXColumn1 = 10; // Starting X for the first column
            let baseXColumn2 = 150; // Starting X for the second column, adjust as needed
            let baseY = 30;
            const fixedLength = 70;
            const lineHeight = 8; // The height of each line

            page2Data.forEach((item: any, index: number) => {
                // console.log(item.company.name.length);

                const companyName = item.company.name.trim().padEnd(fixedLength, ' ');
                const currentBaseX = index % 2 === 0 ? baseXColumn1 : baseXColumn2;

                // Print text in the appropriate column
                pdf.text(`${item.booth}     ${companyName}`, currentBaseX, baseY);
                // console.log(`${companyName} ${item.booth}`)
                // If we've just printed in the second column, or if we've reached the bottom of the page
                if (index % 2 === 1 || ((index / 2 + 1) % 30 === 0 && index % 2 === 0)) {
                    baseY += lineHeight; // Move down to the next line
                }

                // Check if we need to add a new page and reset counters
                if ((index / 2 + 1) % 30 === 0 && index % 2 === 1) {
                    pdf.addPage();
                    baseY = 30; // Reset Y position back to the top for the new page
                }
            });

            // Save the PDF document
            pdf.save(`exhibitors_${new Date().toISOString()}.pdf`);

        }, 500);

    }
    return (
        <>
            <RowWrapper>
                <TopWrapper>
                    <TopLeftWrapper>
                        <div className="event-title">{event?.full_name}</div>
                    </TopLeftWrapper>
                    <TopRightWrapper>
                        <Button color="success" endIcon={<LaunchIcon />} href={event?.link}>
                            Home
                        </Button>
                        <Button color="success" endIcon={<PrintIcon />} onClick={onPrint}>
                            Print
                        </Button>
                    </TopRightWrapper>
                </TopWrapper>
            </RowWrapper>
            <RowWrapper>
                <ExhibitorWapper>
                    <ExhibitorList exhibitors={exhibitors.filter((item: any) => item.status == 'approved')} onClickItem={onClickItem} onHoverItem={onHoverItem} />
                </ExhibitorWapper>
                <StageWrapper ref={bottomWrapperRef}>
                    <Stage
                        ref={stageRef}
                        width={size.width}
                        height={size.height}
                        offsetX={position.x}
                        offsetY={position.y}
                        draggable={true}
                        onWheel={handleWheel}
                        onDragEnd={(e: any) => {
                            console.log(e);
                        }}
                        scaleX={mouseScale}
                        scaleY={mouseScale}
                    >
                        <Layer >
                            <BackgroundImage src={floorplan} />
                            {
                                shapes.map((shape: any) => {
                                    const bookings = exhibitors.filter((exhibitor: any) => exhibitor.booth == shape.no);
                                    return creatShape({
                                        ...shape,
                                        booked: bookings.length > 0,
                                        status: bookings.length > 0 ? bookings[0].status : 'nope'
                                    });
                                })
                            }
                        </Layer>
                    </Stage>
                    <ZoomGroupWrapper>
                        <ToggleButtonGroup
                            value={zoom_type}
                            orientation="vertical"
                            exclusive
                            // onChange={(e, newVal): any => handleZoomEvent(newVal)}
                            aria-label="text alignment"
                        >
                            <ToggleButton value="zoom-in" aria-label="left aligned" onClick={() => handleZoomEvent('zoom-in')}>
                                <ZoomInIcon />
                            </ToggleButton>
                            <ToggleButton value="zoon-out" aria-label="centered" onClick={() => handleZoomEvent('zoom-out')}>
                                <ZoomOutIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </ZoomGroupWrapper>
                </StageWrapper>
            </RowWrapper >
            <DialogBookingDetails
                isDialogOpen={showDialog}
                handleCloseDialog={() => setShowDialog(false)}
                bookingInfo={bookingInfo}
            />
        </>
    );
};

export default FloorPlanKonva;
