import { Container, HeaderTitle, SubmitButton, EventForm } from "./style";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import { FormDataSource } from "../../util/formDataSource";
import { Left, Right } from "./style";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { addEvent, getEvent, updateEvent } from '../../api/eventsService';


interface Props {
  eventInputs: FormDataSource[];
  actionMode: string;
}
export type IUploadStatus = "NO_IMAGE" | "UPLOADING" | "UPLOADED";

const NewEvent: React.FC<Props> = ({ eventInputs, actionMode }) => {

  const { id }: any = useParams();
  // states
  const [file, setFile] = useState<File | null>(null);
  // upload status for disabling submit button
  const [uploadStatus, setUploadStatus] = useState<IUploadStatus>("NO_IMAGE");
  const [data, setData]: any = useState({
    timeStamp: new Date(),
    image: `uploads/images/noimage.png`,
  });
  const navigate = useNavigate();
  
  useEffect(() => {
   
  }, []);

  useEffect(() => {
    console.log(actionMode);
    if (actionMode == 'edit') {
      getEvent(id).then((item) => {
        setData(item);
      }).catch((err) => {
        console.log('error', err.message);
      })
    }
  }, [actionMode]);

  useEffect(() => {
    if (file) {
      setData({
        ...data,
        image: file.name,
        file: file
      });
      setUploadStatus("UPLOADED");
    }
  }, [file]);

  // handle form submiting
  const handleSubmit = async (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    // console.log('form data', data);
    let result = null;
    try {
      if (actionMode === 'edit') {
        result = await updateEvent(data);
      } else {
        result = await addEvent(data);
      }
      navigate('/events');

    } catch (error: any) {
      console.log(error.message);
    }

  };

  //handle image upload
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setUploadStatus("UPLOADING");
    } else {
      setUploadStatus("NO_IMAGE");
    }
  };

  // handle form inputs
  const handleInput = (e: any) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({ ...data, [id]: value });
  };

  // JSX Return
  return (
    <Layout>
      <HeaderTitle>{actionMode === 'edit' ? 'Edit Event' : 'Add New Event'}</HeaderTitle>
      <Container>
        {/* Image upload */}
        <Left>
          <img
            src={
              file
                ? URL.createObjectURL(file)
                : `${process.env.REACT_APP_SERVER_URL}/${data.image}`
            }
            alt="img"
            className="img"
          />
        </Left>
        <Right>
          {/* Input form */}
          <EventForm
            onSubmit={(e) =>
              handleSubmit(e)
            }
          >
            <div className="file">
              <p className="inputTitle">Upload Image:</p>
              <label htmlFor="file">
                <DriveFolderUploadIcon className="icon" />
              </label>
            </div>
            <input
              onChange={handleFile}
              name="img"
              style={{ display: "none" }}
              type="file"
              id="file"
            />
            {/* Generating inputs from JSON */}
            {eventInputs.map((input) => (
              <div key={input.id} className="input">
                <label className="inputTitle">{input.label}</label>
                <br />
                <input
                  onChange={handleInput}
                  id={input.id}
                  className="inputField"
                  type={input.type}
                  placeholder={input.placeholder}
                  value={data[input.id]}
                  disabled={!input.label}
                />
              </div>
            ))}
            {/* <div className="input">
              <label htmlFor="map_id" className="inputTitle">Map</label>
              <br />
              <select id="map_id" className="inputField" value={data.map_id} onChange={handleInput}>
                <option value="-1">Select Map</option>
                {
                  maps.map((item: any) => {
                    return (<option key={item.id} value={item.id}>{item.name}</option>)
                  })
                }
              </select>
            </div> */}
            {/* <div className="input">
              <label htmlFor="status" className="inputTitle">Status</label>
              <br />
              <select id="status" className="inputField" value={data.status} onChange={handleInput}>
                <option value="-1">Select Status</option>
                {
                  status.map((item: any) => {
                    return (<option key={item.id} value={item.id}>{item.name}</option>)
                  })
                }
              </select>
            </div> */}
            <div className="full-width">
              <label className="inputTitle">{"Booth Booking Terms & Conditions"}</label>
              <br />
              <textarea
                onChange={handleInput}
                id={'terms_and_conditions'}
                className="termsAndConditionsField"
                placeholder={"Input Terms and Conditions"}
                value={data['terms_and_conditions']}
                rows={5}
              />
            </div>
            {uploadStatus === "UPLOADING" ? (
              <SubmitButton disabled type="submit">
                Uploading...
              </SubmitButton>
            ) : (
              <SubmitButton type="submit">SUBMIT</SubmitButton>
            )}
          </EventForm>
        </Right>
      </Container>
    </Layout>
  );
};

export default NewEvent;
