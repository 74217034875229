import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { companiesColumns } from "./companysTableData";
import { getCompanys, deleteCompany } from "../../api/companysService";
import SearchTorch from '../../components/search/SearchTorch';
import {
  ActionContainer,
  AddCompany,
  DataGridContainer,
  DataGridWrapper,
} from "./style";
import EventSelect from './EventSelect';

type ListType = "User" | "Product" | "Order" | "Events" | "Companys";

const Companys = () => {
  const location = useLocation();
  // change content on different path
  const type: ListType = "Companys";
  const { id }: any = useParams();

  const [companys, setCompanys]: any = useState([]);
  const [eventId, setEventId] = useState('');

  useEffect(() => {
    const event_id = localStorage.getItem('companys_event_id');
    setEventId(event_id || '');
    getCompanys(event_id || '').then((data: any) => {
      // const new_data = data.filter((company: any) => company.event?.deleted == "no");

      // const new_data = data.filter((company: any) => company);
      const filteredData = [];
      const emailSet = new Set();

      for (const item of data) {
        if (!emailSet.has(item.primary_email)) {
          emailSet.add(item.primary_email);
          filteredData.push(item);
        }
      }
      setCompanys(filteredData);

    });
    return () => {

    }
  }, []);
  // Handle deleting user from db
  const handleDelete = async (id: string) => {
    try {
      await deleteCompany(id);
      const filtered_data: any = companys.filter((item: any) => item.id !== id);
      setCompanys(filtered_data);
    } catch (err) {
      console.log(err);
    }
  };
  const getCompanysByEvent = (event_id: any) => {
    // localStorage.setItem('companys_event_id', event_id);
    // setEventId(event_id);
    // getCompanys(event_id).then(results => {
    //   const new_data = results.filter((company: any) => company.event.deleted == "no");
    //   setCompanys(new_data);
    // }).catch(err => {
    //   console.log(err.message);
    // })
  }
  const onSearch = async (search_text: string) => {

    getCompanys('').then((data: any) => {

      const filteredData = [];
      const emailSet = new Set();

      for (const item of data) {
        if (
          !emailSet.has(item.primary_email) &&
          item.name.toLowerCase().includes(search_text.toLowerCase())
        ) {
          emailSet.add(item.primary_email);
          filteredData.push(item);
        }
      }

      setCompanys(filteredData);

    }).catch(err => {
      console.log(err.message);
    });

  }
  // Generic table fields
  const actions: GridColDef[] = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <ActionContainer>
            <Link
              to={`${location.pathname}/${params.row.id}`}
              state={{ ...params.row }}
              style={{ textDecoration: "none" }}
            >
              <button className="btn viewBtn">View</button>
            </Link>
            <button
              onClick={() => handleDelete(params.row.id)}
              className="btn deleteBtn"
            >
              Delete
            </button>
          </ActionContainer>
        );
      },
    },
  ];

  return (
    <Layout>
      <AddCompany>
        <h1 className="title">Companies</h1>
        {/* <EventSelect onSelect={getCompanysByEvent} selected={eventId} /> */}
        <SearchTorch onSearch={onSearch} />
        <Link
          to={`${location.pathname}/new`}
          state={{ type }}
          style={{ textDecoration: "none" }}
        >
          <button className="add">Add Company</button>
        </Link>

      </AddCompany>

      <DataGridWrapper>
        <DataGridContainer>
          <DataGrid
            style={{ backgroundColor: "white" }}
            rows={companys}
            columns={companiesColumns.concat(actions)}
            pageSize={10}
            rowsPerPageOptions={[10]}
          // checkboxSelection
          />
        </DataGridContainer>
      </DataGridWrapper>
    </Layout>
  );
};

export default Companys;
