import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
export const applicantsColumns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 50 },
  {
    field: "name",
    headerName: "Name",
    width:300,
  },
  {
    field: "event",
    headerName: "Event",
    width:200,
    valueGetter: (params) => params.row.event?.title || ''
  },
  {
    field: "company",
    headerName: "Company",
    width:450,
    valueGetter: (params) => params.row.company?.name || ''
  },
  {
    field: "booth",
    headerName: "Booth",
    width:100,
  },
  {
    field: "payment",
    headerName: "Payment Type",
    width:150,
  },
  {
    field: "status",
    headerName: "Status",
    width:100
  },
];
