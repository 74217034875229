import GlobalStyle from "./shared/globalStyles";
import { ThemeProvider } from "styled-components";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";

import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import Events from "./pages/events/Events";
import NewEvent from './pages/events/NewEvent';

import Maps from "./pages/maps/Maps";
import NewMap from "./pages/maps/NewMap";
import DrawMap from "./pages/maps/DrawMap";

import Companys from "./pages/companys/Companys";
import NewCompany from './pages/companys/NewCompany';

import Applicants from './pages/applicants/Applicants';
import NewApplicant from './pages/applicants/NewApplicant';
import SingleApplicant from './pages/applicants/SingleView';

import Exhibitors from "./pages/exhibitors/Exhibitors";
import FloorPlan from "./pages/floorplan/FloorPlan";
import Users from "./pages/users/Users";
import Profile from "./pages/profile/Profile";

import Colleagues from "./pages/colleagues/Colleagues";

import {
  // formOrderInput,
  // formProductInput,
  formUserInputs,
  formEventInput,
  formMapInput,
  formCompanyInput,
  formApplicantInput,

} from "./util/formDataSource";
import React, { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { darkTheme, lightTheme } from "./shared/theme";
import { AuthContext } from "./context/AuthContext";
import { setSearch } from "./algolia";
import NewColleague from "./pages/colleagues/NewColleague";
import Permissions from "./pages/permissions/Permissions";
import EditPermission from "./pages/permissions/EditPermission";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { isDarkMode } = useContext(DarkModeContext);

  // Authentication
  const { user } = useContext(AuthContext);
  interface AuthProps {
    children: JSX.Element;
  }
  const RequireAuth: React.FC<AuthProps> = ({ children }) => {
    return !!user ? children : <Navigate to="/login" />;
  };

  // Set search data
  setSearch();

  return (
    // THEME PROVIDER FOR THEME SWITCHING
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      {/* GLOBAL STYLES */}
      <GlobalStyle />
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            {/* <Route path="exhibitors" element={<Exhibitors />} /> */}
            <Route path="exhibitors">
              <Route
                path=":id"
                element={
                  <Exhibitors />
                }
              />
            </Route>
            <Route path="floorplans">
              <Route
                path=":id"
                element={
                  <FloorPlan />
                }
              />
            </Route>
            <Route
              index
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route path="users">
              <Route
                index
                element={
                  <RequireAuth>
                    <Users />
                  </RequireAuth>
                }
              />
              <Route
                path="profile"
                element={
                  <RequireAuth>
                    <Profile userInputs={formUserInputs} />
                  </RequireAuth>
                }
              />
              {/* <Route
                path=":id"
                element={
                  <RequireAuth>
                    <Single />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <New userInputs={formUserInputs} />
                  </RequireAuth>
                }
              /> */}
            </Route>
            {/* <Route path="products">
              <Route
                index
                element={
                  <RequireAuth>
                    <List />
                  </RequireAuth>
                }
              />
              <Route
                path=":id"
                element={
                  <RequireAuth>
                    <Single />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={<New userInputs={formProductInput} />}
              />
            </Route>
            <Route path="orders">
              <Route
                index
                element={
                  <RequireAuth>
                    <List />
                  </RequireAuth>
                }
              />
              <Route
                path=":id"
                element={
                  <RequireAuth>
                    <Single />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <New userInputs={formOrderInput} />
                  </RequireAuth>
                }
              />
            </Route> */}
            <Route path="events">
              <Route
                index
                element={
                  <RequireAuth>
                    <Events />
                  </RequireAuth>
                }
              />
              <Route
                path=":id"
                element={
                  <RequireAuth>
                    <NewEvent eventInputs={formEventInput} actionMode={'edit'} />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewEvent eventInputs={formEventInput} actionMode={'new'} />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="maps">
              <Route index
                element={
                  <RequireAuth>
                    <Maps />
                  </RequireAuth>
                }
              />
              <Route
                path=":id"
                element={
                  <RequireAuth>
                    <NewMap mapInputs={formMapInput} actionMode={'edit'} />
                  </RequireAuth>
                }
              />
              <Route
                path="draw/:id"
                element={
                  <RequireAuth>
                    <DrawMap />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewMap mapInputs={formMapInput} actionMode={'new'} />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="companys">
              <Route
                index
                element={
                  <RequireAuth>
                    <Companys />
                  </RequireAuth>
                }
              />
              <Route
                path=":id"
                element={
                  <RequireAuth>
                    <NewCompany Inputs={formCompanyInput} actionMode="edit" />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewCompany Inputs={formCompanyInput} actionMode="new" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="applicants">
              <Route
                index
                element={
                  <RequireAuth>
                    <Applicants />
                  </RequireAuth>
                }
              />
              <Route
                path=":id"
                element={
                  <RequireAuth>
                    <NewApplicant Inputs={formApplicantInput} actionMode="edit" />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewApplicant Inputs={formApplicantInput} actionMode="new" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="colleagues">
              <Route
                index
                element={
                  <RequireAuth>
                    <Colleagues />
                  </RequireAuth>
                }
              />
              <Route
                path=":id"
                element={
                  <RequireAuth>
                    <NewColleague inputs={formUserInputs} actionMode="edit" />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewColleague inputs={formUserInputs} actionMode="new" />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="permissions">
              <Route
                index
                element={
                  <RequireAuth>
                    <Permissions />
                  </RequireAuth>
                }
              />
              <Route
                path=":id"
                element={
                  <RequireAuth>
                    <EditPermission inputs={formUserInputs} actionMode="edit" />
                  </RequireAuth>
                }
              />
            </Route>
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
